import { ClipboardController, IconButton, SkeletonText } from '@antcorefinance/ui';
import { LinkExternal } from '@antcorefinance/ui';
import { useWallet } from '@aptos-labs/wallet-adapter-react';
import { Cog6ToothIcon, DocumentDuplicateIcon, LinkIcon } from '@heroicons/react/24/outline';
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';
import { formatUSD } from 'antc/format';
import { message } from 'antd';
import Title from 'antd/es/typography/Title';
import { networkNameToNetwork } from 'config/chains';
import { Aptos } from 'config/coins';
import { useNetwork } from 'lib/common/use-network';
import { useStablePrice } from 'lib/common/use-stable-price';
import React, { Dispatch, SetStateAction } from 'react';
import { ProfileView } from './user-profile';
import { tasks } from '@/components/ListMain';
import { Button } from '@antcorefinance/ui';
import { CheckCircle2 } from 'lucide-react';

interface Props {
  balance: number | undefined;
  setView: Dispatch<SetStateAction<ProfileView>>;
  faction: number | null;
  userPoints: number;
  userData: any;
  id: number | null;
  referrals: number;
  referralPoints: number;
}

const factions = [
  {
    id: 1,
    name: 'Red Swarm',
    color: 'bg-red-600',
    description: 'Unite under the Red Swarm banner.',
  },
  {
    id: 2,
    name: 'Blue Nest',
    color: 'bg-blue-600',
    description: 'Organize and strategize with the Blue Nest.',
  },
  {
    id: 3,
    name: 'Purple Hive',
    color: 'bg-purple-600',
    description: 'Innovate and adapt within the Purple Hive.',
  },
];

export const DefaultView = ({
  balance,
  setView,
  faction,
  userPoints,
  userData,
  id,
  referrals,
  referralPoints,
}: Props) => {
  const { account, disconnect } = useWallet();
  const { network } = useNetwork();
  console.log('soso');

  // Check if user has completed both required tasks for Ant Scout role
  const hasCompletedAntScoutTasks =
    userData.completedTasks?.some((task: any) => task.taskId === 1) &&
    userData.completedTasks?.some((task: any) => task.taskId === 11);

  console.log(userData.completedTasks);
  const hasCompletedAntMM = userData.completedTasks?.some((task: any) => task.taskId === 5);
  const hasClaimedAntMM = userData.claimedRoles?.some((role: any) => role.role === 'AntcoreMM');

  const hasClaimedAntScout = userData.claimedRoles?.some((role: any) => role.role === 'AntScout');
  // Check if user qualifies for Builder role (more than 14k points)
  const qualifiesForBuilder = userPoints > 14000;

  // Check if user has completed all subtasks (for Ambassador role)
  const requiredSubtaskIds = tasks.flatMap((group) => group.tasks.map((subtask) => subtask.id));
  const hasCompletedAllTasks = requiredSubtaskIds.every((subtaskId) =>
    userData.completedTasks?.some((completedTask: any) => completedTask.taskId === subtaskId),
  );

  // API call helper for claiming a Discord role
  const claimRole = async (role: 'AntScout' | 'AntcoreMM' | 'Ambassador') => {
    try {
      if (!account?.address) {
        message.error('Wallet not connected.');
        return;
      }

      const response = await fetch('/api/claim-discord-role', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          walletAddress: account.address,
          role,
        }),
      });
      const data = await response.json();

      if (data.success) {
        message.success(data.message);
      } else {
        message.error(data.message || `Error claiming Antcore Scout role.`);
      }
    } catch (error) {
      console.error(`Error claiming ${role} role:`, error);
      message.error(`Error claiming ${role} role.`);
    }
  };

  // Handlers for claiming roles
  const handleClaimAntScoutRole = async () => {
    await claimRole('AntScout');
  };

  const handleClaimAntcoreMMRole = async () => {
    await claimRole('AntcoreMM');
  };

  const handleClaimAmbassadorRole = async () => {
    await claimRole('Ambassador');
  };

  let [big, portion] = (balance ? `${balance}` : '0.00').split('.');
  portion = portion ? portion.substring(0, 2) : '';

  const price = useStablePrice({ currency: Aptos[network] });

  // Find the faction name and color based on the factionId
  const factionData = factions.find((f) => f.id === faction) || { name: '', color: '' };
  const { name: factionName, color: factionColor } = factionData;

  const copyReferralUrl = () => {
    navigator.clipboard.writeText(`${window.location.origin}/?ref_id=${id}`);
    message.success({
      content: (
        <div className={`rounded-lg`}>
          <div className="flex min-w-[23rem] flex-row items-center justify-center gap-2">
            <Title style={{ color: '#fff', marginBottom: '0' }} level={2}>
              <div className="flex flex-row gap-3 px-4 py-2">
                <p className="!mb-0 font-mono !text-sm text-green sm:!text-lg">
                  Successfully copied
                </p>
              </div>
            </Title>
          </div>
        </div>
      ),
      className: 'ant-messagex rounded-lg',
      duration: 3,
      icon: <></>,
    });
  };

  return (
    <>
      <div className="flex flex-col gap-8">
        <div className="mx-auto w-full max-w-2xl rounded-[14px] border-white/10">
          {/* Header Section */}
          <div className="mb-8 text-center">
            {/* <div className="inline-block rounded-full">
              <div className="rounded-md bg-gray-900 px-6 py-2">
                <p className={`text-xl font-bold text-white`}>{factionName}</p>
              </div>
            </div> */}

            {/* Discord Info */}
            {userData.discordUsername && (
              <div className="rounded-lg pb-4 pl-1 pt-0">
                <div className="flex items-center justify-center gap-4">
                  <div className="flex flex-1 flex-row items-center justify-start gap-1 truncate text-left">
                    <p className="text-base font-semibold text-gray-400">hi,</p>
                    <p className="text-lg font-medium text-white">{userData.discordUsername}</p>
                    {/* <p className="font-mono text-sm text-gray-400">ID: {userData.discordId}</p> */}
                  </div>
                </div>
              </div>
            )}

            {/* Points Display */}
            <div className="space-y-4">
              <div className="relative overflow-hidden rounded-xl border-white/10 bg-white/5 p-6 shadow-sm">
                <p className="text-3xl font-bold text-white">
                  <span className="bg-gradient-to-r from-yellow-400 to-amber-500 bg-clip-text text-transparent">
                    {userPoints.toLocaleString()}
                  </span>
                </p>
                <p className="mt-1 text-sm font-semibold text-gray-400">eANTC ⚡ Points</p>
              </div>
            </div>
          </div>

          {/* Discord Roles Grid */}
          <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
            {true && (
              <div className="relative overflow-hidden rounded-xl border-white/10 bg-white/5 p-6 shadow-sm">
                <div className="to-cyan-400 absolute inset-x-0 top-0 h-1 bg-blue-500" />
                <p className="text-lg font-bold text-blue-400">Antcore Scout</p>
                <p className="mt-2 text-sm text-gray-400">Completed initial onboarding tasks</p>

                <Button
                  variant="antc"
                  onClick={handleClaimAntScoutRole}
                  disabled={hasClaimedAntScout || !hasCompletedAntScoutTasks}
                  className="mt-4 w-full !bg-[#077d1e]"
                  // className="mt-4 w-full rounded-lg bg-blue-600 py-3 font-medium text-white transition-all hover:bg-blue-700 hover:shadow-blue-800/50"
                >
                  {hasCompletedAntScoutTasks ? (
                    hasClaimedAntScout ? (
                      <>
                        <CheckCircle2 color="#2ab246" />
                        Already Claimed
                      </>
                    ) : (
                      <>
                        <CheckCircle2 color="#2ab246" />
                        Claim Role
                      </>
                    )
                  ) : (
                    'Not Eligible'
                  )}
                </Button>
              </div>
            )}

            {true && (
              <div className="relative overflow-hidden rounded-xl border-white/10 bg-white/5 p-6 shadow-sm">
                <div className="to-cyan-400 absolute inset-x-0 top-0 h-1 bg-blue-500" />
                <p className="bg-gradient-to-r from-yellow-400 to-amber-500 bg-clip-text text-lg font-bold text-transparent">
                  Antcore MM
                </p>
                <ul className="mt-2 list-inside list-disc text-sm text-gray-400">
                  <li>Provided liquidity on Antcore Mainnet</li>
                  <li>Participated in the Hi-Five Launchpad</li>
                </ul>

                <Button
                  variant="antc"
                  onClick={handleClaimAntcoreMMRole}
                  disabled={hasClaimedAntMM || !hasCompletedAntMM}
                  className="mt-4 w-full !bg-[#077d1e]"
                  // className="mt-4 w-full rounded-lg bg-blue-600 py-3 font-medium text-white transition-all hover:bg-blue-700 hover:shadow-blue-800/50"
                >
                  {hasCompletedAntMM ? (
                    hasClaimedAntMM ? (
                      <>
                        <CheckCircle2 color="#2ab246" />
                        Already Claimed
                      </>
                    ) : (
                      <>
                        <CheckCircle2 color="#2ab246" />
                        Claim Role
                      </>
                    )
                  ) : (
                    'Not Eligible'
                  )}
                </Button>
              </div>
            )}

            {/* 
            {qualifiesForBuilder && (
              <div className="relative overflow-hidden rounded-xl bg-gray-700 p-6 shadow-lg">
                <div className="from-green-500 absolute inset-x-0 top-0 h-1 bg-gradient-to-r to-emerald-400" />
                <p className="text-green-400 text-lg font-bold">Builder</p>
                <p className="mt-2 text-sm text-gray-400">14,000+ Points Contributor</p>
                <button
                  onClick={handleClaimBuilderRole}
                  className="bg-green-600 hover:bg-green-700 hover:shadow-green-800/50 mt-4 w-full rounded-lg py-3 font-medium text-white transition-all"
                >
                  Claim Role
                </button>
              </div>
            )} */}

            {/* {hasCompletedAllTasks && (
              <div className="relative overflow-hidden rounded-xl bg-gray-700 p-6 shadow-lg">
                <div className="absolute inset-x-0 top-0 h-1 bg-gradient-to-r from-purple-500 to-fuchsia-400" />
                <p className="text-lg font-bold text-purple-400">Ambassador</p>
                <p className="mt-2 text-sm text-gray-400">Completed all ecosystem tasks</p>
                <button
                  onClick={handleClaimAmbassadorRole}
                  className="mt-4 w-full rounded-lg bg-purple-600 py-3 font-medium text-white transition-all hover:bg-purple-700 hover:shadow-purple-800/50"
                >
                  Claim Role
                </button>
              </div>
            )} */}
          </div>

          {/* Disconnect Section */}
          <div className="mt-4 flex justify-center">
            <button
              onClick={() => disconnect()}
              className="flex items-center gap-2 rounded-lg px-6 py-2 text-gray-400 transition-all hover:text-white"
            >
              <ArrowLeftOnRectangleIcon className="h-5 w-5" />
              <span className="font-medium">Disconnect</span>
            </button>
          </div>
        </div>
      </div>
    </>
    // <div className="flex flex-col gap-8">
    //   {/* Uncomment or modify this section as needed for settings, copy address, etc. */}
    //   {/* <div className="flex justify-between gap-2">
    //     <div className="flex gap-2">
    //       <IconButton
    //         size="sm"
    //         icon={Cog6ToothIcon}
    //         onClick={() => setView(ProfileView.Settings)}
    //         description="Settings"
    //         name="Settings"
    //       />
    //       <ClipboardController hideTooltip>
    //         {({ setCopied, isCopied }) => (
    //           <IconButton
    //             size="sm"
    //             icon={DocumentDuplicateIcon}
    //             onClick={() => setCopied(account?.address as string)}
    //             description={isCopied ? "Copied!" : "Copy Address"}
    //             name="Copy"
    //           />
    //         )}
    //       </ClipboardController>
    //       <LinkExternal
    //         href={`https://explorer.aptoslabs.com/account/${account?.address}?network=${networkNameToNetwork(network)}`}
    //       >
    //         <IconButton
    //           size="sm"
    //           icon={LinkIcon}
    //           description="View on Explorer"
    //           name="View on Explorer"
    //         />
    //       </LinkExternal>
    //     </div>
    //   </div> */}
    //   <div className="mx-auto max-w-md rounded-lg bg-white p-6 shadow-md dark:bg-gray-800">
    //     {/* Header Section */}
    //     <div className="mb-6 text-center">
    //       <p
    //         className={`inline-block rounded-md px-4 py-2 font-mono text-xl font-semibold ${factionColor}`}
    //       >
    //         {factionName} Faction
    //       </p>
    //       {!price || !balance ? (
    //         <div className="mt-2 inline-block w-12">
    //           {/* <SkeletonText fontSize="default" /> */}
    //         </div>
    //       ) : (
    //         <p className="mt-2 font-medium text-slate-400">{/* {formatUSD(price * balance)} */}</p>
    //       )}
    //       <p className="mt-4 text-xl font-semibold">eANTC ⚡ Points: {userPoints}</p>
    //     </div>

    //     {/* Discord Roles Grid */}
    //     <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
    //       {hasCompletedAntScoutTasks && (
    //         <div className="flex flex-col items-center rounded bg-gray-100 p-4 shadow dark:bg-gray-700">
    //           <p className="text-center text-lg font-bold">Ant Scout</p>
    //           <button
    //             onClick={handleClaimAntScoutRole}
    //             className="mt-3 w-full rounded bg-blue-500 py-2 text-white hover:bg-blue-600"
    //           >
    //             Claim
    //           </button>
    //         </div>
    //       )}

    //       {qualifiesForBuilder && (
    //         <div className="flex flex-col items-center rounded bg-gray-100 p-4 shadow dark:bg-gray-700">
    //           <p className="text-center text-lg font-bold">Builder</p>
    //           <button
    //             onClick={handleClaimBuilderRole}
    //             className="bg-green-500 hover:bg-green-600 mt-3 w-full rounded py-2 text-white"
    //           >
    //             Claim
    //           </button>
    //         </div>
    //       )}

    //       {hasCompletedAllTasks && (
    //         <div className="flex flex-col items-center rounded bg-gray-100 p-4 shadow dark:bg-gray-700">
    //           <p className="text-center text-lg font-bold">Ambassador</p>
    //           <button
    //             onClick={handleClaimAmbassadorRole}
    //             className="mt-3 w-full rounded bg-purple-500 py-2 text-white hover:bg-purple-600"
    //           >
    //             Claim
    //           </button>
    //         </div>
    //       )}
    //     </div>

    //     {/* Disconnect Button */}
    //     <div className="mt-6 text-center">
    //       <IconButton
    //         size="sm"
    //         icon={ArrowLeftOnRectangleIcon}
    //         onClick={() => disconnect()}
    //         description="Disconnect"
    //         name="Disconnect"
    //       />
    //     </div>
    //   </div>
    // </div>
  );
};
