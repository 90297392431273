'use client';

import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

function ReferralUrlListener() {
  const searchParams = useSearchParams();
  const router = useRouter();

  useEffect(() => {
    // Get 'ref_id' and 'id' from search params
    const refId = searchParams.get('ref_id');
    const recapId = searchParams.get('id');

    if (refId) {
      localStorage.setItem('ref_id', refId);
    }

    if (recapId) {
      localStorage.setItem('ref_id', recapId);
    }

    // Remove parameters from URL
    const params = new URLSearchParams(searchParams);
    // params.delete('ref_id');
    // params.delete('id');

    const newUrl = `${window.location.pathname}${params.toString() ? '?' + params.toString() : ''}`;

    // Replace current route with cleaned-up URL (without ref_id and id)
    // router.replace(newUrl);
  }, [searchParams, router]);

  return null;
}

export default ReferralUrlListener;
